<template>
    <TabMenu :model='nestedRouteItems' class='mb-5' />
</template>
<script>

export default {
    name: 'ManagerUserTabMenu',
    props: {
        managerUserId: {},
    },
    data() {
        return {
            nestedRouteItems: [],
        };
    },
    created() {
        this.nestedRouteItems = [
            {
                label: 'Temel Bilgiler',
                to: '/ManagerUser/Edit/' + this.managerUserId,
            },
            {
                label: 'Yetki Tanımları',
                to: '/ManagerUser/Auth/' + this.managerUserId,
            },
            {
                label: 'Dosyalar',
                to: '/ManagerUser/Files/' + this.managerUserId,
            },
            {
                label: 'Sayfalar',
                to: '/ManagerUser/Pages/' + this.managerUserId,
            },
        ];
    },
};
</script>