<template>
    <AdminToast/>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <ManagerUserLeftPanel :data-item='dataItem' />
                </div>

                <span>
					<hr>
				</span>

                <span>

                    <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger p-component mr-2 pl-3 pr-3 pt-2 pb-2' />

                <Sidebar v-model:visible="visibleLeft" :baseZIndex="1000">
					<h3>Yetki Tanımlamaları</h3>

					<p class="text-lg">
						<b>Proje Seçimi:</b> Kullanıcının yetkili olduğu projeyi seçin. İlgili olmadığı projeleri seçmeniz verilerin yanlış yönetilmesine yol açabilir.<br>
						<br>
					</p>

                    <span><hr></span>
                    <span class="text-red-500 font-bold">*</span> doldurulması zorunlu alanlardır.<br>

				</Sidebar>

				</span>

            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <ManagerUserTabMenu :manager-user-id='managerUserId'></ManagerUserTabMenu>

                <div class='grid'>

                    <div class='col-12'>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Proje Seçimi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <MultiSelect id='selectedProjects' v-model='selectedProjects' :options='projectList' optionLabel='name' option-value='id' placeholder='Proje seçin' />
                            </div>
                        </div>

                        <div class='field grid mb-5 hidden'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Maaş Değiştirme</label>
                            <div class='col-12 md:col-10'>
                                <InputSwitch id='sallaryEdit' v-model='dataItem.canEditSalary' />
                            </div>
                        </div>

                        <div class='field grid'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                            <div class='col-12 md:col-10'>
                                <Button id='saveButton' label='Kaydet' icon='pi pi-save' class='mr-2 mb-2' @click='save'></Button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>

import ManagerUserService from '@/services/managerUserService';
import { showErrorMessage } from '@/components/shared/toast/toastFunction';
import { showSuccessMessage } from '../../components/shared/toast/toastFunction';
import ProjectService from '@/services/projectService';
import ManagerUserProjectService from '../../services/manangerUserProjectService';
import AdminToast from "@/components/shared/toast/adminToast";
import ManagerUserLeftPanel from './managerUserLeftPanel';
import ManagerUserTabMenu from './managerUserTabMenu';
import { checkUserRole } from '../common/commonConstantFunctions';
import { getManagerUser } from '../common/commonFunctions';

export default {
    _managerUserService: null,
    _projectService: null,
    _managerUserProjectService:null,
    components: { ManagerUserTabMenu, AdminToast, ManagerUserLeftPanel},
    created() {
        this._managerUserService = new ManagerUserService();
        this._managerUserProjectService = new ManagerUserProjectService();
        this._projectService = new ProjectService();
        this.managerUserId = this.$route.params.managerUserId;
    },
    async mounted() {
        checkUserRole(this,'Admin');
        this.dataItem = await getManagerUser(this.managerUserId);
        await this.fillProject();
        await this.fillManagerUserProject(this.managerUserId);
    },
    data() {
        return {
            canEditSalary:false,
            selectedProjects: [],
            projectList: [],
            visibleLeft: false,
            managerUserId: 0,
            dataItem: {
                name: '',
                surName: '',
                isActive: true,
                email: '',
                gsmNumber: '',
                password: '',
                userType: '',
                passiveDate: '',
                passiveReason: '',
                canEditSalary: false
            },
        };
    },

    methods: {
        async save() {
            const updateResponse = await this._managerUserService.updateManagerAuth(this.managerUserId, this.dataItem);
            if (updateResponse.isSuccess) {
                await this.updateManagerProjects();
                showSuccessMessage(this, 'Yetki Güncelleme Başarılı');
            } else {
                showErrorMessage(this, 'Yetki Tanımları Güncellemede Sorun İle Karşılaşıldı');
            }
        },
        async getUser(managerUserId) {
            let getResponse = await this._managerUserService.getManagerUserById(managerUserId);
            if (getResponse.isSuccess) {
                this.dataItem.name = getResponse.data.name;
                this.dataItem.surName = getResponse.data.surName;
                this.dataItem.userType = getResponse.data.userType;
                this.dataItem.canEditSalary = getResponse.data.canEditSalary;
                this.dataItem.profilePicture = getResponse.data.profilePicture;
            } else {
                showErrorMessage(this, getResponse.message);
            }
        },
        async updateManagerProjects() {
            await this._managerUserProjectService.deleteProjects(this.managerUserId);
            for (const value of this.selectedProjects) {
                await this._managerUserProjectService.insertProjects(this.managerUserId, value);
            }
        },

        async fillProject() {
            let projectResponse = await this._projectService.getAllProjects();
            if (projectResponse.isSuccess) {
                this.projectList = projectResponse.data;
            }
        },

        async fillManagerUserProject(managerUserId) {
            let getResponse = await this._managerUserProjectService.getAllProjects(managerUserId);
            if(getResponse.isSuccess)
            {
                this.selectedProjects = getResponse.data;
            }
        }

    },

};

</script>


<style scoped>

</style>
