<template>
    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>
        <div class='profile relative'>
            <img v-if='dataItem.profilePicture != null && dataItem.profilePicture != ""' :src="'http://image.a1catering.com.tr/'+dataItem.profilePicture" width='100' class='border-circle border-3 border-solid' />
        </div>
        <div class='ml-3 mb-2 md:mb-0'>

			<span class='text-4xl line-height-3 font-bold block'>{{ dataItem.name }} {{ dataItem.surName }}</span>

            <span class='block subtext mb-3'>{{ dataItem.userType }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ManagerUserLeftPanel',
    props: {
        dataItem: {},
    },
};
</script>
