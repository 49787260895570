import { deleteDataById, getListData, insertData } from './commonService';

export default class ManagerUserProjectService {

    #defaultUrl = "/api/ManagerUser/Project";
    #defaultResourceName = "Proje";

    async insertProjects(managerUserId, projectId) {
        const createData = {
            "projectId": projectId,
            "managerUserId": managerUserId,
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async deleteProjects(managerUserId) {
        return await deleteDataById(this.#defaultUrl, managerUserId, this.#defaultResourceName);
    }

    async getAllProjects(managerUserId) {
        return await getListData(this.#defaultUrl + "/" + managerUserId + "/IdList", this.#defaultResourceName);
    }
}